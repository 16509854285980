<template>
  <CustomBottomSheet
    :refName="'UserFamilyInfo'"
    size="xl"
    :headerText="$t('UserFamilies.data')"
    :headerIcon="userFamily.icon"
  >
    <div class="row">
      <DataLabelGroup
        :className="'col-md-6'"
        :value="userFamily.fullCode"
        :title="$t('UserFamilies.code')"
        :imgName="'code.svg'"
      />
      <DataLabelGroup
        :className="'col-md-6'"
        :value="userFamily.userFamilyNameAr"
        :title="$t('UserFamilies.nameAr')"
        :imgName="'UserFamilies.svg'"
      />
      <DataLabelGroup
        :className="'col-md-6'"
        :value="userFamily.userFamilyNameEn"
        :title="$t('UserFamilies.nameEn')"
        :imgName="'UserFamilies.svg'"
      />
      <DataLabelGroup
        :className="'col-md-6'"
        :value="userFamily.userFamilyNameUnd"
        :title="$t('UserFamilies.nameUnd')"
        :imgName="'UserFamilies.svg'"
      />
      <DataLabelGroup
        :className="'col-md-6'"
        :value="
          userFamily.relativeRelationTypeData.relativeRelationTypeNameCurrent
        "
        :title="$t('RelativeRelationTypes.name')"
        :imgName="'RelativeRelationTypes.svg'"
      />
      <DataLabelGroup
        :className="'col-md-6'"
        :value="userFamily.nationalityCountryData.nationalityNameCurrent"
        :title="$t('Countries.selectNationality')"
        :imgName="'countries.svg'"
      />
      <DataLabelGroup
        :className="'col-md-6'"
        :value="userFamily.generalJobData.generalJobNameCurrent"
        :title="$t('Jobs.name')"
        :imgName="'generalJobs.svg'"
      />
      <DataLabelGroup
        :className="'col-md-6'"
        :value="userFamily.userFamilyEmail"
        :title="$t('Users.email')"
        :imgName="'email.svg'"
      />
      <DataLabelGroup
        :className="'col-md-6 phone-number'"
        :value="userFamily.userFamilyPhone1WithCC"
        :title="$t('Users.userPhone1')"
        :imgName="'phone.svg'"
      />
      <CustomCheckbox
        :className="'col-md-6'"
        :value="userFamily.userFamilyPhone1WhatsAppStatus"
        v-on:changeValue="userFamily.userFamilyPhone1WhatsAppStatus = $event"
        :title="$t('Users.whatsAppStatus')"
        :imgName="'type.svg'"
        :showAsInfo="true"
      />
      <DataLabelGroup
        :className="'col-md-6 phone-number'"
        :value="userFamily.userFamilyPhone2WithCC"
        :title="$t('Users.userPhone2')"
        :imgName="'phone.svg'"
      />
      <CustomCheckbox
        :className="'col-md-6'"
        :value="userFamily.userFamilyPhone2WhatsAppStatus"
        v-on:changeValue="userFamily.userFamilyPhone2WhatsAppStatus = $event"
        :title="$t('Users.whatsAppStatus')"
        :imgName="'type.svg'"
        :showAsInfo="true"
      />
      <DataLabelGroup
        :className="'col-md-6 phone-number'"
        :value="userFamily.userFamilyPhone3WithCC"
        :title="$t('Users.userPhone3')"
        :imgName="'phone.svg'"
      />
      <CustomCheckbox
        :className="'col-md-6'"
        :value="userFamily.userFamilyPhone3WhatsAppStatus"
        v-on:changeValue="userFamily.userFamilyPhone3WhatsAppStatus = $event"
        :title="$t('Users.whatsAppStatus')"
        :imgName="'type.svg'"
        :showAsInfo="true"
      />

      <DataLabelGroup
        :className="'col-md-6'"
        :value="userFamily.userFamilyDescriptionAr"
        :title="$t('UserFamilies.descriptionAr')"
        :imgName="'description.svg'"
      />
      <DataLabelGroup
        :className="'col-md-6'"
        :value="userFamily.userFamilyAddressAr"
        :title="$t('UserFamilies.addressAr')"
        :imgName="'address.svg'"
      />
      <DataLabelGroup
        :className="'col-md-6'"
        :value="userFamily.userFamilyAddressEn"
        :title="$t('UserFamilies.addressEn')"
        :imgName="'address.svg'"
      />
      <DataLabelGroup
        :className="'col-md-6'"
        :value="userFamily.userFamilyAddressUnd"
        :title="$t('UserFamilies.addressUnd')"
        :imgName="'address.svg'"
      />
      <CustomCheckbox
        :className="'col-md-6'"
        :value="userFamily.emergencyContactStatus"
        v-on:changeValue="userFamily.emergencyContactStatus = $event"
        :title="$t('general.emergencyContact')"
        :imgName="'type.svg'"
        :showAsInfo="true"
      />
      <DataLabelGroup
        :className="'col-md-6'"
        :value="userFamily.userFamilyDescriptionEn"
        :title="$t('UserFamilies.descriptionEn')"
        :imgName="'description.svg'"
      />
      <DataLabelGroup
        :className="'col-md-6'"
        :value="userFamily.userFamilyDescriptionUnd"
        :title="$t('UserFamilies.descriptionUnd')"
        :imgName="'description.svg'"
      />
      <DataLabelGroup
        :className="'col-md-6'"
        :value="userFamily.userFamilyNotes"
        :title="$t('notes')"
        :imgName="'notes.svg'"
      />
    </div>
  </CustomBottomSheet>
</template>

<script>
import CustomBottomSheet from "./../../../../components/general/CustomBottomSheet.vue";
import DataLabelGroup from "./../../../../components/general/DataLabelGroup.vue";
import CustomCheckbox from "./../../../../components/general/CustomCheckbox.vue";

export default {
  components: {
    CustomBottomSheet,
    DataLabelGroup,
    CustomCheckbox,
  },
  props: ["userFamily"],
};
</script>
