import { render, staticRenderFns } from "./UserFamilyChangeActivationType.vue?vue&type=template&id=efa5f826&"
import script from "./UserFamilyChangeActivationType.vue?vue&type=script&lang=js&"
export * from "./UserFamilyChangeActivationType.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports