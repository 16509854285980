<template>
  <div class="">
    <form autocomplete="off">
      <div class="row">
        <CustomFileInput
          :className="'col-12'"
          @changeValue="userFamily.mediaFile = $event.file"
          :defaultImg="
            fullPathFileFromServer(
              userFamily.userFamilyImagePath,
              userFamily.defaultImg
            )
          "
          :deleteFileStatus="
            deleteFileStatus &&
            !userFamily.userFamilyImageIsDefault &&
            hasPrivilegeDeleteImage
          "
          v-on:deleteFile="$emit('deleteFile')"
        />
      </div>

      <div class="my-card">
        <div class="row">
          <CustomInput
            :className="'col-md-6'"
            :id="`${id}-fullCode`"
            :value="userFamily.fullCode"
            v-on:changeValue="userFamily.fullCode = $event"
            :title="$t('UserFamilies.code')"
            :imgName="'code.svg'"
          />
          <CustomInput
            :className="'col-md-6'"
            :id="`${id}-userFamilyNameAr`"
            :errors="errors_userFamilyNameAr"
            :value="userFamily.userFamilyNameAr"
            v-on:changeValue="
              userFamily.userFamilyNameAr = $event;
              $v.userFamily.userFamilyNameAr.$touch();
            "
            :title="$t('UserFamilies.nameAr')"
            :imgName="'UserFamilies.svg'"
          />
          <CustomInput
            :className="'col-md-6'"
            :id="`${id}-userFamilyNameEn`"
            :errors="errors_userFamilyNameEn"
            :value="userFamily.userFamilyNameEn"
            v-on:changeValue="
              userFamily.userFamilyNameEn = $event;
              $v.userFamily.userFamilyNameEn.$touch();
            "
            :title="$t('UserFamilies.nameEn')"
            :imgName="'UserFamilies.svg'"
          />
          <CustomInput
            :className="'col-md-6'"
            :id="`${id}-userFamilyNameUnd`"
            :value="userFamily.userFamilyNameUnd"
            v-on:changeValue="userFamily.userFamilyNameUnd = $event"
            :title="$t('UserFamilies.nameUnd')"
            :imgName="'UserFamilies.svg'"
          />
          <CustomSelectBox
            :className="'col-md-6'"
            :id="`${id}-relativeRelationTypeToken`"
            :value="userFamily.relativeRelationTypeToken"
            :options="relativeRelationTypeTokenOptions"
            v-on:changeValue="userFamily.relativeRelationTypeToken = $event"
            :title="$t('RelativeRelationTypes.select')"
            :imgName="'RelativeRelationTypes.svg'"
          />
          <CustomSelectBox
            :className="'col-md-6'"
            :id="`${id}-nationalityCountryToken`"
            :value="userFamily.nationalityCountryToken"
            :options="nationalityCountryTokenOptions"
            v-on:changeValue="userFamily.nationalityCountryToken = $event"
            :title="$t('Countries.selectNationality')"
            :imgName="'countries.svg'"
          />
          <CustomSelectBox
            :className="'col-md-6'"
            :id="`${id}-generalJobToken`"
            :value="userFamily.generalJobToken"
            :options="generalJobTokenOptions"
            v-on:changeValue="userFamily.generalJobToken = $event"
            :title="$t('Jobs.select')"
            :imgName="'generalJobs.svg'"
          />
          <CustomInput
            :className="'col-md-6'"
            :id="`${id}-userFamilyEmail`"
            :value="userFamily.userFamilyEmail"
            v-on:changeValue="userFamily.userFamilyEmail = $event"
            :title="$t('Users.email')"
            :imgName="'email.svg'"
          />

          <CustomPhonePicker
            :className="'col-md-9'"
            :id="`${id}-userFamilyPhone1`"
            :countryCodeName="userFamily.userFamilyPhone1CountryCodeName"
            :countryCode="userFamily.userFamilyPhone1CountryCode"
            :phoneNumber="userFamily.userFamilyPhone1"
            :title="$t('Users.userPhone1')"
            :imgName="'phone.svg'"
            v-on:changeValue="setPhone1($event)"
          />
          <CustomCheckbox
            :className="'col-md-3'"
            :value="userFamily.userFamilyPhone1WhatsAppStatus"
            v-on:changeValue="
              userFamily.userFamilyPhone1WhatsAppStatus = $event
            "
            :title="$t('Users.whatsAppStatus')"
            :imgName="'type.svg'"
          />
          <CustomPhonePicker
            :className="'col-md-9'"
            :id="`${id}-userFamilyPhone2`"
            :countryCodeName="userFamily.userFamilyPhone2CountryCodeName"
            :countryCode="userFamily.userFamilyPhone2CountryCode"
            :phoneNumber="userFamily.userFamilyPhone2"
            :title="$t('Users.userPhone2')"
            :imgName="'phone.svg'"
            v-on:changeValue="setPhone2($event)"
          />
          <CustomCheckbox
            :className="'col-md-3'"
            :value="userFamily.userFamilyPhone2WhatsAppStatus"
            v-on:changeValue="
              userFamily.userFamilyPhone2WhatsAppStatus = $event
            "
            :title="$t('Users.whatsAppStatus')"
            :imgName="'type.svg'"
          />
          <CustomPhonePicker
            :className="'col-md-9'"
            :id="`${id}-userFamilyPhone3`"
            :countryCodeName="userFamily.userFamilyPhone3CountryCodeName"
            :countryCode="userFamily.userFamilyPhone3CountryCode"
            :phoneNumber="userFamily.userFamilyPhone3"
            :title="$t('Users.userPhone3')"
            :imgName="'phone.svg'"
            v-on:changeValue="setPhone3($event)"
          />
          <CustomCheckbox
            :className="'col-md-3'"
            :value="userFamily.userFamilyPhone3WhatsAppStatus"
            v-on:changeValue="
              userFamily.userFamilyPhone3WhatsAppStatus = $event
            "
            :title="$t('Users.whatsAppStatus')"
            :imgName="'type.svg'"
          />
          <TextArea
            :className="'col-md-6'"
            :id="`${id}-userFamilyAddressAr`"
            :value="userFamily.userFamilyAddressAr"
            v-on:changeValue="userFamily.userFamilyAddressAr = $event"
            :title="$t('UserFamilies.addressAr')"
            :imgName="'address.svg'"
          />
          <TextArea
            :className="'col-md-6'"
            :id="`${id}-userFamilyAddressEn`"
            :value="userFamily.userFamilyAddressEn"
            v-on:changeValue="userFamily.userFamilyAddressEn = $event"
            :title="$t('UserFamilies.addressEn')"
            :imgName="'address.svg'"
          />
          <TextArea
            :className="'col-md-6'"
            :id="`${id}-userFamilyAddressUnd`"
            :value="userFamily.userFamilyAddressUnd"
            v-on:changeValue="userFamily.userFamilyAddressUnd = $event"
            :title="$t('UserFamilies.addressUnd')"
            :imgName="'address.svg'"
          />
          <CustomCheckbox
            :className="'col-md-6'"
            :value="userFamily.emergencyContactStatus"
            v-on:changeValue="userFamily.emergencyContactStatus = $event"
            :title="$t('general.emergencyContact')"
            :imgName="'type.svg'"
          />

          <TextArea
            :className="'col-md-6'"
            :id="`${id}-userFamilyDescriptionAr`"
            :errors="errors_userFamilyDescriptionAr"
            :value="userFamily.userFamilyDescriptionAr"
            v-on:changeValue="
              userFamily.userFamilyDescriptionAr = $event;
              $v.userFamily.userFamilyDescriptionAr.$touch();
            "
            :title="$t('UserFamilies.descriptionAr')"
            :imgName="'description.svg'"
          />
          <TextArea
            :className="'col-md-6'"
            :id="`${id}-userFamilyDescriptionEn`"
            :errors="errors_userFamilyDescriptionEn"
            :value="userFamily.userFamilyDescriptionEn"
            v-on:changeValue="
              userFamily.userFamilyDescriptionEn = $event;
              $v.userFamily.userFamilyDescriptionEn.$touch();
            "
            :title="$t('UserFamilies.descriptionEn')"
            :imgName="'description.svg'"
          />
          <TextArea
            :className="'col-md-6'"
            :id="`${id}-userFamilyDescriptionUnd`"
            :value="userFamily.userFamilyDescriptionUnd"
            v-on:changeValue="userFamily.userFamilyDescriptionUnd = $event"
            :title="$t('UserFamilies.descriptionUnd')"
            :imgName="'description.svg'"
          />
          <TextArea
            :className="'col-md-6'"
            :id="`${id}-userFamilyNotes`"
            :value="userFamily.userFamilyNotes"
            v-on:changeValue="userFamily.userFamilyNotes = $event"
            :title="$t('notes')"
            :imgName="'notes.svg'"
          />
        </div>
      </div>

      <div class="form-actions">
        <div class="icon-submit" @click.prevent="submitForm">
          <img src="@/assets/images/check-icon.svg" :title="submitName" />
        </div>
        <div
          @click.prevent
          class="icon-cancel"
          v-b-modal="`ConfirmCloseSheet-${bottomSheetName}`"
        >
          <img src="@/assets/images/cancel-icon.svg" :title="$t('cancel')" />
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { USER_TYPE } from "./../../../../utils/constantLists";
import {
  fullPathFileFromServer,
  bottomSheetScrollToTop,
} from "./../../../../utils/functions";
import {
  isValidNameAr,
  isValidNameEn,
  isValidTextAr,
  isValidTextEn,
} from "./../../../../utils/validationHelper";
import CustomFileInput from "./../../../../components/general/CustomFileInput.vue";
import CustomSelectBox from "./../../../../components/general/CustomSelectBox.vue";
import CustomCheckbox from "./../../../../components/general/CustomCheckbox.vue";
import CustomPhonePicker from "./../../../../components/general/CustomPhonePicker.vue";
import CustomInput from "./../../../../components/general/CustomInput.vue";
import TextArea from "./../../../../components/general/TextArea.vue";
import {
  hasAdminsEgyptFamilyDeleteImage,
  hasMasterAdminFamilyDeleteImage,
  hasEmployeeFamilyDeleteImage,
  hasStudentFamilyDeleteImage,
} from "./../../../../utils/privilegeHelper";
import { getGeneralJobsDialog } from "./../../../../utils/dialogsOfApi";
import { checkPrivilege } from "./../../../../utils/functions";
import { getRelativeRelationTypesDialog } from "./../../../../utils/dialogsOfApi";
import generalMixin from "./../../../../utils/generalMixin";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import Countries from "./../../../../models/settings/settingsOthers/countries/Countries";

export default {
  mixins: [generalMixin, validationMixin],
  components: {
    CustomFileInput,
    CustomSelectBox,
    CustomCheckbox,
    CustomPhonePicker,
    CustomInput,
    TextArea,
  },
  data() {
    return {
      countries: new Countries(),
      nationalityCountryTokenOptions: [],
      generalJobTokenOptions: [],
      relativeRelationTypeTokenOptions: [],
    };
  },
  props: {
    userFamily: {
      type: Object,
    },
    userTypeToken: {
      type: String,
      default: "",
    },
    submitName: {
      type: String,
    },
    id: {
      type: String,
      default: "id",
    },
    deleteFileStatus: {
      type: Boolean,
      default: false,
    },
    bottomSheetName: {
      type: String,
      default: "",
    },
  },
  validations: {
    userFamily: {
      userFamilyNameAr: { required, isValidNameAr },
      userFamilyNameEn: { required, isValidNameEn },
      userFamilyDescriptionAr: { isValidTextAr },
      userFamilyDescriptionEn: { isValidTextEn },
    },
  },
  computed: {
    errors_userFamilyNameAr() {
      let errors = [];
      if (this.$v.userFamily.userFamilyNameAr.$error) {
        if (
          !this.$v.userFamily.userFamilyNameAr.required &&
          !this.$v.userFamily.userFamilyNameEn.required
        )
          errors.push(this.$t("validation.fieldIsRequired"));
        else if (
          this.$v.userFamily.userFamilyNameAr.required &&
          !this.$v.userFamily.userFamilyNameAr.isValidNameAr
        )
          errors.push(this.$t("validation.nameIsRequiredInAr"));
      }
      return errors;
    },
    errors_userFamilyNameEn() {
      let errors = [];
      if (this.$v.userFamily.userFamilyNameEn.$error) {
        if (
          !this.$v.userFamily.userFamilyNameAr.required &&
          !this.$v.userFamily.userFamilyNameEn.required
        )
          errors.push(this.$t("validation.fieldIsRequired"));
        else if (
          this.$v.userFamily.userFamilyNameEn.required &&
          !this.$v.userFamily.userFamilyNameEn.isValidNameEn
        )
          errors.push(this.$t("validation.nameIsRequiredInEn"));
      }
      return errors;
    },
    errors_userFamilyDescriptionAr() {
      let errors = [];
      if (this.$v.userFamily.userFamilyDescriptionAr.$error) {
        if (!this.$v.userFamily.userFamilyDescriptionAr.isValidTextAr)
          errors.push(this.$t("validation.fieldIsRequiredInAr"));
      }
      return errors;
    },
    errors_userFamilyDescriptionEn() {
      let errors = [];
      if (this.$v.userFamily.userFamilyDescriptionEn.$error) {
        if (!this.$v.userFamily.userFamilyDescriptionEn.isValidTextEn)
          errors.push(this.$t("validation.fieldIsRequiredInEn"));
      }
      return errors;
    },
    hasPrivilegeDeleteImage() {
      let status = false;
      switch (this.userTypeToken) {
        case USER_TYPE.AdminsEgypt:
          status = checkPrivilege(hasAdminsEgyptFamilyDeleteImage());
          break;
        case USER_TYPE.MasterAdmin:
          status = checkPrivilege(hasMasterAdminFamilyDeleteImage());
          break;
        case USER_TYPE.Employee:
          status = checkPrivilege(hasEmployeeFamilyDeleteImage());
          break;
        case USER_TYPE.Student:
          status = checkPrivilege(hasStudentFamilyDeleteImage());
          break;
      }
      return status;
    },
  },
  methods: {
    setPhone1(data) {
      this.userFamily.userFamilyPhone1CountryCodeName = data.countryCodeName;
      this.userFamily.userFamilyPhone1CountryCode = data.countryCode;
      this.userFamily.userFamilyPhone1 = data.phoneNumber;
    },
    setPhone2(data) {
      this.userFamily.userFamilyPhone2CountryCodeName = data.countryCodeName;
      this.userFamily.userFamilyPhone2CountryCode = data.countryCode;
      this.userFamily.userFamilyPhone2 = data.phoneNumber;
    },
    setPhone3(data) {
      this.userFamily.userFamilyPhone3CountryCodeName = data.countryCodeName;
      this.userFamily.userFamilyPhone3CountryCode = data.countryCode;
      this.userFamily.userFamilyPhone3 = data.phoneNumber;
    },
    async getGeneralJobsDialog() {
      this.isLoading = true;
      this.generalJobTokenOptions = await getGeneralJobsDialog();
      this.isLoading = false;
    },
    async getNationalityDialog() {
      this.isLoading = true;
      this.nationalityCountryTokenOptions =
        await this.countries.country.getNationalityDialog(
          this.countries.filterData
        );
      this.isLoading = false;
    },
    async submitForm() {
      bottomSheetScrollToTop();
      this.$v.$touch();
      if (
        ((this.$v.userFamily.userFamilyNameAr.required &&
          this.$v.userFamily.userFamilyNameAr.isValidNameAr) ||
          (this.$v.userFamily.userFamilyNameEn.required &&
            this.$v.userFamily.userFamilyNameEn.isValidNameEn)) &&
        this.$v.userFamily.userFamilyDescriptionAr.isValidTextAr &&
        this.$v.userFamily.userFamilyDescriptionEn.isValidTextEn
      ) {
        this.$emit("submitForm");
      }
    },
    async getRelativeRelationTypesDialog() {
      this.isLoading = true;
      this.relativeRelationTypeTokenOptions =
        await getRelativeRelationTypesDialog();
      this.isLoading = false;
    },
    fullPathFileFromServer,
  },
  created() {
    this.getNationalityDialog();
    this.getGeneralJobsDialog();
    this.getRelativeRelationTypesDialog();
  },
};
</script>
